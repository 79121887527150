import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import Footer from './Components/Partials/Footer';
import TermsofService from './Components/Info Pages/TermsofService';
import PrivacyPolicy from './Components/Info Pages/PrivacyPolicy';
import Blog from './Components/Blog';
import BlogPost from './Components/BlogPost';
import NavBar from './Components/Partials/NavBar';
import FAQ from './Components/Info Pages/FAQ';
import Contact from './Components/Contact';
import StudyBuddyLanding from './Components/StudyBuddy/StudyBuddyLanding';
import CookiesPage from './Components/Pages/CookiesPage';
import About from './Components/Pages/About';

// Scroll to top component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <NavBar />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/terms-of-service" element={<TermsofService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/studybuddy' element={<StudyBuddyLanding />} />
          <Route path='/cookies' element={<CookiesPage />} />
          <Route path='/about' element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
