/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaTimes, FaBook, FaLightbulb, FaBlog, FaChevronRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import CognoraLogo from '../../assets/Logo empty inverse (1).png';
import '../Styles/NavBar.css';

const NavBar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();

    // Prevent body scroll when mobile menu is open
    useEffect(() => {
        if (isNavOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isNavOpen]);

    // Close mobile menu when route changes
    useEffect(() => {
        setIsNavOpen(false);
    }, [location]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsNavOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isActive = (path) => location.pathname === path;

    return (
        <header className="header">
            <nav className="navbar">
                <Link to="/" className="navbar__brand">
                    <img 
                        src={CognoraLogo} 
                        alt="Cognora Logo" 
                        className="navbar__logo" 
                        width="38" 
                        height="38"
                    />
                    <span className="navbar__title">Cognora</span>
                </Link>

                <div className="navbar__menu">
                    <Link 
                        to="/about" 
                        className={`navbar__link ${isActive('/about') ? 'navbar__link--active' : ''}`}
                    >
                        <FaLightbulb /> 
                        <span>About Us</span>
                    </Link>
                    <Link 
                        to="/blog" 
                        className={`navbar__link ${isActive('/blog') ? 'navbar__link--active' : ''}`}
                    >
                        <FaBlog /> 
                        <span>Blog</span>
                    </Link>
                    <a 
                        href="https://studdybuddy.ca/" 
                        className="navbar__link navbar__link--cta"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <FaBook /> 
                        <span>StudyBuddy</span>
                        <FaChevronRight style={{ fontSize: '0.875rem' }} />
                    </a>
                </div>

                <button 
                    className="navbar__toggle"
                    onClick={() => setIsNavOpen(!isNavOpen)}
                    aria-label="Toggle navigation"
                    aria-expanded={isNavOpen}
                >
                    {isNavOpen ? <FaTimes /> : <FaBars />}
                </button>

                <div 
                    className={`navbar__mobile-backdrop ${isNavOpen ? 'navbar__mobile-backdrop--visible' : ''}`}
                    onClick={() => setIsNavOpen(false)}
                />

                <div 
                    className={`navbar__mobile ${isNavOpen ? 'navbar__mobile--open' : ''}`}
                    ref={dropdownRef}
                    aria-hidden={!isNavOpen}
                >
                    <div className="navbar__mobile-links">
                        <Link 
                            to="/about" 
                            className={`navbar__link ${isActive('/about') ? 'navbar__link--active' : ''}`}
                            onClick={() => setIsNavOpen(false)}
                        >
                            <FaLightbulb /> 
                            <span>About Us</span>
                        </Link>
                        <Link 
                            to="/blog" 
                            className={`navbar__link ${isActive('/blog') ? 'navbar__link--active' : ''}`}
                            onClick={() => setIsNavOpen(false)}
                        >
                            <FaBlog /> 
                            <span>Blog</span>
                        </Link>
                        <a 
                            href="https://studdybuddy.ca/" 
                            className="navbar__link navbar__link--cta"
                            target="_blank" 
                            rel="noopener noreferrer"
                            onClick={() => setIsNavOpen(false)}
                        >
                            <FaBook /> 
                            <span>StudyBuddy</span>
                            <FaChevronRight style={{ fontSize: '0.875rem' }} />
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default NavBar;