import axios from 'axios';

const API_URL = 'https://api.studybuddy.lekan.ca';

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const makeRequest = async (method, url, data = null, config = {}) => {
  return axiosInstance({
    method,
    url,
    data,
    ...config,
  });
};

export const sendContactMessage = async (contactData) => {
    try {
      const response = await makeRequest('post', '/contact', contactData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };