import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Styles/Blog.css';
import blog1Image from '../assets/blog1.jpg';

const Blog = () => {
  const blogPosts = [
    {
      id: 1,
      title: "StudyBuddy: Where AI Meets Education",
      excerpt: "Announcing StudyBuddy, our AI-powered learning companion that adapts to your unique learning journey...",
      date: "2024-11-01",
      displayDate: "November 1st, 2024",
      category: "Product Release",
      imageUrl: blog1Image,
      author: {
        name: "Founder, Cognora",
        avatar: "/images/founder-avatar.jpg",
        bio: "Founder and AI researcher at Cognora"
      },
      tags: ["AI Education", "EdTech", "Product Launch", "StudyBuddy"]
    }
  ];

  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Cognora Blog",
    "description": "Insights on AI Innovation and Educational Technology",
    "publisher": {
      "@type": "Organization",
      "name": "Cognora",
      "logo": {
        "@type": "ImageObject",
        "url": "https://cognora.ca/logo.png",
        "width": "112",
        "height": "112"
      }
    },
    "blogPost": [
      {
        "@type": "BlogPosting",
        "headline": "StudyBuddy: Where AI Meets Education",
        "datePublished": "2024-11-01",
        "author": {
          "@type": "Person",
          "name": "Founder, Cognora"
        },
        "image": {
          "@type": "ImageObject",
          "url": "https://cognora.ca/blog1.jpg",
          "width": "1200",
          "height": "630"
        }
      }
    ]
  }`;

  return (
    <>
      <Helmet>
        <title>Cognora Blog | AI Education Insights & Innovation</title>
        <meta name="description" content="Explore the latest insights on AI innovation, educational technology, and the future of learning from Cognora's team of experts." />
        <meta name="keywords" content="AI education blog, educational technology insights, artificial intelligence learning, StudyBuddy updates, AI tutoring news" />
        <link rel="canonical" href="https://cognora.ca/blog" />
        <link rel="alternate" type="application/rss+xml" title="Cognora Blog RSS Feed" href="https://cognora.ca/rss.xml" />
        
        <script type="application/ld+json">
          {structuredData}
        </script>
      </Helmet>

      <div className="blog-container">
        <div className="blog-hero">
          <h1>Cognora Blog</h1>
          <p>Insights on AI, Education, and the Future of Learning</p>
        </div>

        <div className="blog-filters">
          <div className="categories">
            <span className="active">All</span>
            <span>Product Updates</span>
            <span>Research</span>
            <span>Education</span>
          </div>
        </div>

        <div className="blog-grid">
          {blogPosts.map(post => (
            <article key={post.id} className="blog-card">
              <div className="blog-card-image">
                <img src={post.imageUrl} alt={post.title} loading="lazy" />
              </div>
              <div className="blog-card-content">
                <span className="blog-category">{post.category}</span>
                <h2>{post.title}</h2>
                <p>{post.excerpt}</p>
                <div className="blog-tags">
                  {post.tags.map(tag => (
                    <span key={tag} className="tag">{tag}</span>
                  ))}
                </div>
                <div className="blog-card-footer">
                  <div className="blog-meta">
                    <span className="blog-date">{post.displayDate}</span>
                  </div>
                  <Link to={`/blog/${post.id}`} className="read-more">
                    Read More →
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;