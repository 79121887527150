import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRobot, FaBrain, FaChartLine, FaBook, FaClock, FaUsers, FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import '../Styles/StudyBuddy.css';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

const StudyBuddyLanding = () => {
  const [messageInput, setMessageInput] = useState('');

  const features = [
    {
      icon: <FaRobot />,
      title: "AI-Powered Learning",
      description: "Advanced AI technology that adapts to your learning style and pace"
    },
    {
      icon: <FaBrain />,
      title: "Smart Analysis",
      description: "Intelligent breakdown of complex topics into digestible concepts"
    },
    {
      icon: <FaChartLine />,
      title: "Progress Tracking",
      description: "Detailed analytics to monitor your academic improvement"
    },
    {
      icon: <FaBook />,
      title: "Multi-Subject Support",
      description: "Comprehensive coverage across all academic disciplines"
    },
    {
      icon: <FaClock />,
      title: "24/7 Availability",
      description: "Round-the-clock access to educational support"
    },
    {
      icon: <FaUsers />,
      title: "Personalized Experience",
      description: "Tailored conversations based on your unique needs"
    }
  ];

  const [heroRef, heroInView] = useInView({ triggerOnce: true });
  const [featuresRef, featuresInView] = useInView({ triggerOnce: true });

  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "StudyBuddy by Cognora",
    "applicationCategory": "EducationalApplication",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "description": "AI-powered learning companion that provides personalized tutoring."
  }).replace(/</g, '\\u003c');

  const handleSendMessage = () => {
    if (messageInput.trim()) {
      // Encode the message for URL safety
      const encodedMessage = encodeURIComponent(messageInput);
      
      // Construct the external URL with the message as a query parameter
      const externalUrl = `http://studdybuddy.ca/?message=${encodedMessage}&mode=tutor`;
      
      // Open in the same window
      window.location.href = externalUrl;
      
      // Alternatively, to open in a new tab:
      // window.open(externalUrl, '_blank');
    }
  };

  return (
    <>
      <Helmet>
        <title>StudyBuddy | Cognora</title>
        <meta name="description" content="Transform your learning experience with StudyBuddy, an AI-powered tutor that adapts to your unique learning style. Available 24/7 for personalized education support." />
        <meta name="keywords" content="StudyBuddy, AI tutor, personalized learning, online tutoring, educational AI, study help" />
        <link rel="canonical" href="https://cognora.ca/studybuddy" />
        
        <script type="application/ld+json">
          {structuredData}
        </script>
      </Helmet>
      
      <div className="studybuddy-container">
        <section 
          ref={heroRef} 
          className={`studybuddy-hero ${heroInView ? 'animate-in' : ''}`}
        >
          <div className="hero-overlay"></div>
          <h1 className="gradient-text">Transform Your Learning Journey</h1>
          <p>Experience the future of education with AI-powered personalized tutoring</p>
          <div className="studybuddy-cta-buttons">
            <Link to="/studybuddy/try" className="studybuddy-btn studybuddy-btn-primary">
              Meet StudyBuddy
            </Link>
          </div>
        </section>

        <section 
          ref={featuresRef} 
          className={`feature-section ${featuresInView ? 'animate-in' : ''}`}
        >
          <h2 className="section-title">Why Choose StudyBuddy?</h2>
          <div className="feature-grid">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-icon">{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="ai-demo animate-in">
          <div className="demo-background-pattern"></div>
          <h2>Message StudyBuddy</h2>
          <div className="demo-container">
            <div className="input-area empty">
              <div className="input-wrapper empty">
                <textarea
                  className="chat-input scrollable empty"
                  placeholder="Ask StudyBuddy anything..."
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <button 
                  className="send-button"
                  onClick={handleSendMessage}
                  disabled={!messageInput.trim()}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="success-path animate-in">
          <div className="path-connector"></div>
          <h2>Your Path to Academic Success</h2>
          <div className="path-steps">
            {[
              { step: 1, title: "Sign Up", description: "Create your free account" },
              { step: 2, title: "Ask Questions", description: "Start your learning conversations" },
              { step: 3, title: "Get Support", description: "Engage with AI-powered assistance" },
              { step: 4, title: "Track Progress", description: "Monitor your understanding" }
            ].map((step, index) => (
              <div key={index} className="path-step">
                <div className="step-number">{step.step}</div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="final-cta animate-in">
          <div className="floating-shapes"></div>
          <h2>Ready to Transform Your Learning?</h2>
          <p>Join students already chatting with StudyBuddy</p>
          <Link to="https://studdybuddy.ca/" className="cta-button cta-primary">
            Get Started Now <FaArrowRight />
          </Link>
        </section>

        <section className="trust-indicators">
          <div className="trust-grid">
            {[
              "Privacy Focused",
              "24/7 Support",
              "Money-Back Guarantee",
              "Regular Updates"
            ].map((item, index) => (
              <div key={index} className="trust-item">
                <FaCheckCircle />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default StudyBuddyLanding;