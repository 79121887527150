import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Footer.css';
import CognoraLogo from '../../assets/Cognora Logo High Res (1).png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section brand">
          <Link to="/">
            <img src={CognoraLogo} alt="Cognora Logo" className="footerlogo" />
          </Link>
          <p className="brand-description">
          </p>
        </div>

        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            {/* <li><Link to="/careers">Careers</Link></li> */}
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="https://x.com/CognoraAI">X/Twitter</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Solutions</h4>
          <ul>
            <li><Link to="/studybuddy">StudyBuddy</Link></li>
            <span className="separator">More Coming Soon •</span>
            {/* <li><Link to="/api">Education API</Link></li> */}
            {/* <li><Link to="/pricing">Pricing</Link></li> */}
          </ul>
        </div>

        <div className="footer-section">
          <h4>Resources</h4>
          <ul>
            {/* <li><Link to="/documentation">Documentation</Link></li> */}
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>&copy; {new Date().getFullYear()} LEKAN INC. All rights reserved.</p>
          <div className="footer-bottom-links">
            <Link to="/privacy-policy">Privacy</Link>
            <span className="separator">•</span>
            <Link to="/terms-of-service">Terms</Link>
            <span className="separator">•</span>
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;