import React from 'react';
import { Helmet } from 'react-helmet';
import '../Styles/Policy.css';

const TermsofService = () => {
  const lastUpdated = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const tosStructuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Terms of Service",
    "description": "Terms and conditions for using Cognora's AI-powered educational platform and services.",
    "publisher": {
      "@type": "Organization",
      "name": "Cognora"
    },
    "dateModified": new Date().toISOString()
  };

  return (
    <>
      <Helmet>
        <title>Terms of Service | Cognora</title>
        <meta name="description" content="Read Cognora's terms of service for our AI-powered educational platform. Understand our policies, user agreements, and service conditions." />
        <meta name="keywords" content="Cognora terms of service, AI platform terms, educational service agreement, StudyBuddy terms" />
        <link rel="canonical" href="https://cognora.ca/terms-of-service" />
        
        <script type="application/ld+json">
          {JSON.stringify(tosStructuredData)}
        </script>
      </Helmet>
      <div className="policy-container">
        <div className="policy-header">
          <h1>Terms of Service</h1>
          <p className="policy-meta">Last Updated: {lastUpdated}</p>
        </div>

        <div className="policy-toc">
          <h3>Table of Contents</h3>
          <ul>
            <li><a href="#acceptance">1. Acceptance of Terms</a></li>
            <li><a href="#definitions">2. Definitions</a></li>
            <li><a href="#services">3. Services Description</a></li>
            <li><a href="#accounts">4. User Accounts</a></li>
            <li><a href="#usage">5. Acceptable Use</a></li>
            <li><a href="#subscriptions">6. Subscriptions and Payments</a></li>
            <li><a href="#ip">7. Intellectual Property</a></li>
            <li><a href="#data">8. Data Usage and Privacy</a></li>
            <li><a href="#liability">9. Limitation of Liability</a></li>
            <li><a href="#termination">10. Termination</a></li>
            <li><a href="#disputes">11. Dispute Resolution</a></li>
            <li><a href="#modifications">12. Modifications to Service</a></li>
            <li><a href="#international">13. International Use</a></li>
            <li><a href="#educational">14. Educational Institutions</a></li>
            {/* <li><a href="#api">15. API Terms</a></li> */}
          </ul>
        </div>

        <section id="acceptance" className="policy-section">
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing or using any of Cognora's services, including but not limited to StudyBuddy, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service ("Terms"). If you are using our services on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these terms.</p>
          <p>These Terms constitute a legally binding agreement between you and Cognora Inc. ("Cognora," "we," "us," or "our"). If you do not agree with any part of these terms, you must not use our services.</p>
        </section>

        <section id="definitions" className="policy-section">
          <h2>2. Definitions</h2>
          <ul>
            <li>"Service" refers to all products, services, and platforms provided by Cognora, including StudyBuddy, APIs, and educational tools.</li>
            <li>"User" refers to any individual or entity that accesses or uses our Services.</li>
            <li>"Content" includes text, graphics, images, music, software, audio, video, information, or other materials.</li>
            <li>"User Content" means any content submitted, posted, or transmitted by users through the Service.</li>
            <li>"AI Systems" refers to our artificial intelligence and machine learning systems and models.</li>
          </ul>
        </section>

        <section id="services" className="policy-section">
          <h2>3. Services Description</h2>
          <h3>3.1 Core Services</h3>
          <p>Cognora provides AI-powered educational technology services, including:</p>
          <ul>
            <li>Interactive AI tutoring and learning assistance</li>
            <li>Document analysis and study material generation</li>
            <li>Educational content personalization</li>
            <li>Learning progress tracking and analytics</li>
            <li>API access for educational institutions and developers</li>
          </ul>

          <h3>3.2 Service Availability</h3>
          <p>While we strive to provide uninterrupted service, we do not guarantee that our services will be available at all times. We reserve the right to modify, suspend, or discontinue any part of our services without notice.</p>
          
          <h3>3.3 Service Quality</h3>
          <p>Our AI systems are designed to provide educational assistance but may not be perfect. Users should verify important information and exercise judgment when using our services.</p>
        </section>

        <section id="accounts" className="policy-section">
          <h2>4. User Accounts</h2>
          <h3>4.1 Account Creation</h3>
          <p>To access our services, you must create an account using a valid email address. You agree to:</p>
          <ul>
            <li>Provide accurate and complete registration information</li>
            <li>Maintain the security of your account</li>
            <li>Promptly update any changes to your information</li>
            <li>Accept responsibility for all activities under your account</li>
          </ul>

          <h3>4.2 Account Security</h3>
          <p>We use email-based authentication for account access. You are responsible for maintaining the confidentiality of your login credentials and for all activities associated with your account.</p>

          <h3>4.3 Account Restrictions</h3>
          <p>You must be at least 13 years old to create an account. Users under 18 must have parental consent. We reserve the right to suspend or terminate accounts that violate these terms.</p>
        </section>

        <section id="usage" className="policy-section">
          <h2>5. Acceptable Use</h2>
          <h3>5.1 Permitted Use</h3>
          <p>You agree to use our services only for:</p>
          <ul>
            <li>Educational and learning purposes</li>
            <li>Personal academic improvement</li>
            <li>Legitimate research and study</li>
            <li>Authorized institutional use</li>
          </ul>

          <h3>5.2 Prohibited Activities</h3>
          <p>You must not:</p>
          <ul>
            <li>Use our services for academic dishonesty or cheating</li>
            <li>Attempt to circumvent any service limitations or restrictions</li>
            <li>Reverse engineer or attempt to extract our AI models</li>
            <li>Share account access with unauthorized users</li>
            <li>Use our services for any illegal or unauthorized purpose</li>
            <li>Interfere with or disrupt our services or servers</li>
          </ul>
        </section>

        <section id="subscriptions" className="policy-section">
          <h2>6. Subscriptions and Payments</h2>
          <h3>6.1 Subscription Plans</h3>
          <p>We offer various subscription tiers with different features and usage limits. Plan details and current pricing are available on our pricing page.</p>

          <h3>6.2 Payment Terms</h3>
          <ul>
            <li>All payments are processed securely through Stripe</li>
            <li>Subscriptions auto-renew unless cancelled</li>
            <li>Refunds are handled case-by-case within 30 days</li>
            <li>Prices may change with notice to subscribers</li>
          </ul>

          <h3>6.3 Cancellation</h3>
          <p>You may cancel your subscription at any time. Access continues until the end of the current billing period. No partial refunds for unused time.</p>
        </section>

        <section id="ip" className="policy-section">
          <h2>7. Intellectual Property</h2>
          <h3>7.1 Our IP Rights</h3>
          <p>All content, features, and functionality of our services, including but not limited to our AI models, software, algorithms, text, graphics, and logos, are owned by Cognora and protected by intellectual property laws.</p>

          <h3>7.2 User Content Rights</h3>
          <p>You retain rights to content you submit but grant us a license to use, modify, and distribute it to provide our services. This includes using your content to improve our AI systems.</p>
        </section>

        <section id="data" className="policy-section">
          <h2>8. Data Usage and Privacy</h2>
          <p>Our collection and use of personal information is governed by our Privacy Policy. By using our services, you consent to our data practices as described in the Privacy Policy.</p>
        </section>

        <section id="liability" className="policy-section">
          <h2>9. Limitation of Liability</h2>
          <p>Our services are provided "as is" without warranties. We are not liable for:</p>
          <ul>
            <li>Accuracy of AI-generated content</li>
            <li>Service interruptions or data loss</li>
            <li>Indirect or consequential damages</li>
            <li>Third-party content or services</li>
          </ul>
        </section>

        <section id="termination" className="policy-section">
          <h2>10. Termination</h2>
          <p>We may suspend or terminate your access to our services:</p>
          <ul>
            <li>For violations of these terms</li>
            <li>For fraudulent or illegal activities</li>
            <li>To protect our users or services</li>
            <li>Upon your request</li>
          </ul>
        </section>

        <section id="disputes" className="policy-section">
          <h2>11. Dispute Resolution</h2>
          <p>Any disputes will be resolved through binding arbitration in accordance with Canadian law. You agree to resolve disputes individually, not as part of a class action.</p>
        </section>

        <section id="modifications" className="policy-section">
          <h2>12. Modifications to Service</h2>
          <p>We reserve the right to modify or discontinue any part of our services. We will provide notice of significant changes when possible.</p>
        </section>

        <section id="international" className="policy-section">
          <h2>13. International Use</h2>
          <p>Our services are based in Canada but accessible globally. Users must comply with local laws and regulations when using our services.</p>
        </section>

        <section id="educational" className="policy-section">
          <h2>14. Educational Institutions</h2>
          <p>Special terms apply for institutional users. Contact us for enterprise licensing and custom deployments.</p>
        </section>

        {/* <section id="api" className="policy-section">
          <h2>15. API Terms</h2>
          <p>API access is subject to additional terms and rate limits. See our API documentation for details.</p>
        </section> */}

        <div className="policy-footer">
          <p>For questions about these terms, contact us at legal@cognora.ca</p>
        </div>
      </div>
    </>
  );
};

export default TermsofService;
