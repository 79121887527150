import React, { useState } from 'react';
import '../Styles/FAQ.css';
import { Helmet } from 'react-helmet';

const FAQ = () => {
    const [activeCategory, setActiveCategory] = useState('general');

    const categories = {
        general: "General",
        features: "Features & Usage",
        account: "Account & Access",
        pricing: "Pricing & Billing",
        technical: "Technical",
        privacy: "Privacy & Security"
    };

    const faqs = {
        general: [
            {
                question: "What is StudyBuddy?",
                answer: "StudyBuddy is an AI-powered learning companion that provides personalized educational support across all academic subjects. It's designed to offer 24/7 tutoring assistance through various interaction modes."
            },
            {
                question: "What subjects does StudyBuddy cover?",
                answer: "StudyBuddy supports all academic subjects across all educational levels. Whether you're studying mathematics, sciences, humanities, or languages, our AI is equipped to help."
            },
            {
                question: "How does StudyBuddy's AI tutoring work?",
                answer: "StudyBuddy offers four distinct interaction modes: Q&A for quick answers, Tutor for in-depth learning, Brainstorm for idea generation, and Debate for exploring different perspectives. Each mode is optimized for different learning objectives."
            }
        ],
        features: [
            {
                question: "What are the different chat modes available?",
                answer: "StudyBuddy offers four specialized modes: Q&A, Tutor, Brainstorm, and Debate. Each mode is designed for specific learning needs and interaction styles."
            },
            {
                question: "Is there a limit to how many questions I can ask?",
                answer: "Question limits vary by subscription tier. Free users have a basic limit, Premium users get increased limits, while Pro and Ultimate users enjoy unlimited questions."
            },
            {
                question: "Can I upload documents for analysis?",
                answer: "Yes, StudyBuddy supports document uploads for analysis and discussion."
            },
            {
                question: "Can I save my conversations?",
                answer: "Yes, all conversations are automatically saved and can be accessed later from your account."
            },
            {
                question: "How does the Debate mode work?",
                answer: "Debate mode acts as an intellectual sparring partner, presenting different perspectives and arguments - both supporting and challenging - to help you understand topics more deeply. It can play devil's advocate to help you explore all aspects of a subject."
            },
            {
                question: "What's the difference between Q&A and Tutor mode?",
                answer: "While Q&A provides direct answers to specific questions, Tutor mode offers more in-depth, concept-focused responses. It helps navigate through complex topics and ensures deeper understanding rather than just answering questions."
            },
            {
                question: "Can I switch between modes during a conversation?",
                answer: "Yes, you can switch between different modes (Q&A, Tutor, Brainstorm, and Debate) at any time during your conversation to best suit your learning needs."
            }
        ],
        account: [
            {
                question: "How do I create an account?",
                answer: "Simply click 'Sign Up' and enter your email address. We'll send you an authentication link to set up your account"
            },
            {
                question: "How does the login process work?",
                answer: "We use password authentication for secure access. Click 'Login', enter your email and password, then click submit to access your account."
            },
            {
                question: "What if I don't receive the login link?",
                answer: "First, check your spam folder. If you still haven't received the link, you can request a new one. Links expire after 15 minutes for security."
            },
            {
                question: "How many devices can I use?",
                answer: "Currently, you can log in on several devices simultaneously without any specific limit."
            },
            {
                question: "Can I have multiple accounts?",
                answer: "While you can create multiple accounts, we ask users to avoid exploitative behavior that violates our terms of service."
            },
            {
                question: "How do I report issues with my account?",
                answer: "For any account-related issues, please contact our support team directly through the contact form or at support@cognora.ca."
            }
        ],
        pricing: [
            {
                question: "What payment methods do you accept?",
                answer: "We accept all major credit cards through our secure payment processor, Stripe."
            },
            {
                question: "Can I cancel my subscription anytime?",
                answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
            },
            {
                question: "Is there a refund policy?",
                answer: "We offer a 30-day money-back guarantee for all paid plans. Refunds are handled on a case-by-case basis through our support team."
            },
            {
                question: "How do subscription renewals work?",
                answer: "Subscriptions automatically renew at the end of each billing period unless cancelled."
            },
            {
                question: "What happens to my data if I downgrade my subscription?",
                answer: "Your data remains intact when you downgrade your subscription. You'll maintain access to your conversation history and saved content."
            },
            {
                question: "Can I share my subscription with others?",
                answer: "No, subscriptions are for individual use only and cannot be shared between users."
            }
        ],
        technical: [
            {
                question: "Which browsers are supported?",
                answer: "StudyBuddy works on all modern browsers including Chrome, Firefox, Safari, and Edge."
            },
            {
                question: "Is there a mobile app?",
                answer: "A mobile app is currently in development. For now, you can access StudyBuddy through your mobile browser."
            },
            {
                question: "Does StudyBuddy work offline?",
                answer: "Currently, StudyBuddy requires an internet connection to function."
            },
            {
                question: "What's the maximum file size for uploads?",
                answer: "Currently, the maximum file size for uploads is 10MB per file."
            },
            {
                question: "How do I clear my chat history?",
                answer: "You can delete individual chats or clear your entire chat history through the interface options in each conversation."
            }
        ],
        privacy: [
            {
                question: "How is my data protected?",
                answer: "We prioritize user privacy and security. All payments are processed securely through Stripe, and we're compliant with educational privacy standards."
            },
            {
                question: "How long are conversations stored?",
                answer: "Conversations are stored until you choose to delete them or delete your account."
            },
            {
                question: "Can I delete my account and data?",
                answer: "Yes, you can delete your account and all associated data at any time through your account settings."
            },
            {
                question: "How is my payment information handled?",
                answer: "All payment processing is handled securely through Stripe. We never store your payment information directly."
            },
            {
                question: "Are my conversations private?",
                answer: "Yes, all conversations are private and can only be accessed by you through your account."
            }
        ]
    };

        const structuredData = `{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What is StudyBuddy?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "StudyBuddy is an AI-powered learning companion that provides personalized tutoring."
              }
            }]
          }`;

    return (
        <>
            <Helmet>
                <title>Cognora FAQ | Common Questions About Our AI Learning Platform</title>
                <meta name="description" content="Find answers to frequently asked questions about Cognora's AI-powered learning platform, StudyBuddy, account management, and educational features." />
                <meta name="keywords" content="Cognora FAQ, StudyBuddy help, AI tutor questions, educational AI support, learning platform FAQ" />
                <link rel="canonical" href="https://cognora.ca/faq" />
                
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className="faq-container">
                <div className="faq-header">
                    <h1>Frequently Asked Questions</h1>
                    <p>Find answers to common questions about StudyBuddy</p>
                </div>

                <div className="faq-navigation">
                    {Object.entries(categories).map(([key, value]) => (
                        <button 
                            key={key}
                            className={`category-button ${activeCategory === key ? 'active' : ''}`}
                            onClick={() => setActiveCategory(key)}
                        >
                            {value}
                        </button>
                    ))}
                </div>

                <div className="faq-content">
                    {faqs[activeCategory].map((faq, index) => (
                        <details key={index} className="faq-item">
                            <summary>{faq.question}</summary>
                            <p>{faq.answer}</p>
                        </details>
                    ))}
                </div>

                <div className="faq-footer">
                    <p>Still have questions? <a href="/contact">Contact our support team</a></p>
                </div>
            </div>
        </>
    );
};

export default FAQ; 