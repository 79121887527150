import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Styles/Blog.css';
import blog1Image from '../assets/blog1.jpg';

const BlogPost = ({ id }) => {
  const post = {
    title: "StudyBuddy: Where AI Meets Education - Transforming How You Learn",
    date: "2024-11-01",
    displayDate: "November 1st, 2024",
    author: {
      name: "Founder, Cognora",
      bio: "Founder and AI researcher at Cognora, passionate about transforming education through AI innovation."
    },
    category: "Product Release",
    tags: ["AI Education", "EdTech", "Product Launch", "StudyBuddy"],
    excerpt: "Discover StudyBuddy, the AI-powered learning companion that's transforming education through personalized learning experiences and innovative study tools.",
    content: `
      The landscape of education is evolving, and today we're excited to announce StudyBuddy - an AI-powered learning companion that adapts to your unique learning journey. Gone are the days of one-size-fits-all tutoring and rigid learning paths.

      Every student faces unique challenges: complex topics that need clarification at midnight, the struggle to organize study materials effectively, or simply needing a patient tutor to explain concepts differently. StudyBuddy addresses these challenges head-on with a suite of innovative features.

      Key Features That Transform Learning:

      Smart Study Resources & Version Control
      Keep your study materials organized and accessible. StudyBuddy doesn't just store your notes - it understands them. With built-in version control, track your learning progress and never lose important revisions.

      Adaptive Chat Modes
      Switch seamlessly between different learning approaches with Q&A Mode for quick answers, Tutor Mode for in-depth explanations, Brainstorm Mode for creative problem-solving, and Debate Mode to challenge your understanding.

      Personalized Learning Experience
      StudyBuddy's User Memory System learns your preferences, adapts to your learning style, and remembers your progress. The more you interact, the more personalized your experience becomes.

      Integrated Learning Tools
      Access comprehensive study support with PDF document analysis, real-time web search integration, advanced rendering for complex subjects, and code display support for programming topics.

      This initial release marks just the beginning of our journey. We're committed to continuous improvement and expansion of StudyBuddy's capabilities, with regular updates and new features planned for the coming months.

      Join us in transforming the future of education.
    `,
    imageUrl: blog1Image,
    lastModified: "2024-03-15"
  };

  return (
    <>
      <Helmet>
        <title>{post.title} | Cognora Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta name="keywords" content={post.tags.join(", ")} />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "image": post.imageUrl,
            "datePublished": post.date,
            "dateModified": post.lastModified,
            "author": {
              "@type": "Person",
              "name": post.author.name,
              "description": post.author.bio
            },
            "publisher": {
              "@type": "Organization",
              "name": "Cognora",
              "logo": {
                "@type": "ImageObject",
                "url": "https://cognora.ca/logo.png"
              }
            },
            "description": post.excerpt,
            "keywords": post.tags.join(", "),
            "articleBody": post.content
          })}
        </script>
      </Helmet>

      <div className="blog-post-container">
        <div className="blog-card-image full">
          <img src={post.imageUrl} alt={post.title} />
        </div>
        <article className="blog-post">
          <Link to="/blog" className="return-button">
            ← Back to Blog
          </Link>
          <header className="blog-post-header">
            <div className="blog-post-meta-top">
              <span className="blog-post-category">{post.category}</span>
              <time dateTime={post.date}>{post.displayDate}</time>
            </div>
            <h1>{post.title}</h1>
            <div className="blog-tags">
              {post.tags.map(tag => (
                <span key={tag} className="tag">{tag}</span>
              ))}
            </div>
            <div className="blog-post-author">
              <div className="author-info">
                <span className="author-name">By {post.author.name}</span>
                <p className="author-bio">{post.author.bio}</p>
              </div>
            </div>
          </header>

          <div className="blog-post-content">
            {post.content.split('\n\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
          
          <div className="blog-post-cta">
              <Link to="https://studdybuddy.ca/" className="cta-button">
                Meet StudyBuddy - Your New Learning Companion
              </Link>
            </div>

          <footer className="blog-post-footer">
            <div className="share-section">
              <h3>Share this article</h3>
              <div className="share-buttons">
                <a 
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post.title)}&url=${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share-button"
                >
                  <i className="fab fa-twitter"></i>
                  Twitter
                </a>
                <a 
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(post.title)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share-button"
                >
                  <i className="fab fa-linkedin"></i>
                  LinkedIn
                </a>
                <a 
                  href={`mailto:?subject=${encodeURIComponent(post.title)}&body=${encodeURIComponent(window.location.href)}`}
                  className="share-button"
                >
                  <i className="fas fa-envelope"></i>
                  Email
                </a>
              </div>
            </div>

          </footer>
        </article>
      </div>
    </>
  );
};

export default BlogPost; 